import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [2];

export const dictionary = {
		"/(public)": [15,[4]],
		"/(app)/app": [~6,[2]],
		"/(app)/app/(admin)/admin/users": [~7,[2]],
		"/(app)/app/instellingen": [~8,[2,3]],
		"/(app)/app/instellingen/facturering": [9,[2,3]],
		"/(app)/app/instellingen/notificaties": [10,[2,3]],
		"/(app)/app/instellingen/team": [11,[2,3]],
		"/(app)/app/transcripties": [~12,[2]],
		"/(app)/app/transcripties/nieuw": [~14,[2]],
		"/(app)/app/transcripties/[id]": [~13,[2]],
		"/(public)/(auth)/inloggen": [~16,[5]],
		"/(public)/(auth)/registreren": [~17,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';